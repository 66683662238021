<template>
<div>
    <div class="d-flex flex-wrap justify-content-lg-end mt-3 mb-3 mr-3" v-if="!home" style="margin-bottom:-2rem !important;margin-top:4rem !important;">
        <div class="dropdown mb-3 mb-md-0 mr-2">
            <a class="btn btn-sm btn-outline-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Descargar imagen
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style="">
                <button class="dropdown-item" v-on:click='exportImage'>Descargar PNG</button>
                <button class="dropdown-item" v-on:click='exportPDF'>Descargar PDF</button>
            </div>
        </div>
        <button v-if="0" class="btn btn-sm btn-primary btn-download mb-5 mr-2" v-on:click='exportData()'>
            Ver conjunto de datos
        </button>
        <div class="dropdown">
            <a class="btn btn-sm btn-outline-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Descargar datos
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink" style="">
                <button class="dropdown-item" v-on:click='exportCSV()'>Descargar CSV</button>
                <button v-if="0" class="dropdown-item" v-on:click='exportExcel()'>Descargar Excel</button>
            </div>
        </div>
    </div>
    <div v-if="home">
        <div :id="'vizContainer' + componente.orden" v-show="viz !== null"></div>
    </div>
    <div v-else :style="{overflow:chartContainerOverflow}" >
        <div class="card mt-5 mb-4" :style="{width: componente.width? (parseInt(componente.width) + 50) + 'px!important':'auto'}">
            <div class="card-body card-body--chart" style="overflow:hidden; /*max-height: 850px*/">
                <div :id="'vizContainer' + componente.orden"  v-show="viz !== null"></div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: 'GestorChart',
    props: ['componente', 'home'],
    data () {
        return {
            context: {},
            viz: null
        }
    },

    computed: {
        chartContainerOverflow () {
            if (!this.componente.width) {
                return 'visible'
            }
            const anchoComponente = parseInt(this.componente.width) + 50
            const anchoPantalla = window.innerWidth
            const espacioExtra = 550
            if ((anchoPantalla - anchoComponente) > espacioExtra) {
                return 'visible'
            }
            return 'auto'
        }
    },

    methods: {
        exportImage () {
            this.viz.showExportImageDialog()
        },
        exportPDF () {
            this.viz.showExportPDFDialog()
        },
        exportCSV (comp) {
            if (comp !== undefined) { this.viz.showExportCrossTabDialog(comp) } else { this.viz.showExportCrossTabDialog() }
        },
        exportExcel (comp) {
            if (comp !== undefined) { this.viz.exportCrossTabToExcel(comp) } else { this.viz.exportCrossTabToExcel() }
        },
        exportData (comp) {
            if (comp !== undefined) { this.viz.showExportDataDialog()(comp) } else { this.viz.showExportDataDialog()() }
        },
        initViz () {
            var containerDiv = document.getElementById('vizContainer' + this.componente.orden)
            var url = this.componente.url
            var mobile = this.isMobile()
            var options = {
                width: (this.componente?.width || '700') + 'px',
                height: (this.componente?.height || '900') + 'px',
                hideTabs: true,
                hideToolbar: true,
            }
            if (mobile) {
                options = {
                    height: '900px',
                    hideTabs: true
                }
            }
            this.viz = new window.tableau.Viz(containerDiv, url, options)
        },
        isMobile () {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    mounted () {
        var self = this
        if (window && window.tableau) {
            self.initViz()
        } else {
            setTimeout(function () { self.initViz() }, 2000)
        }
    }
}
</script>
<style>
#vizContainer{
    text-align: center;
}
iframe{
    margin: 0 auto;
}
</style>
