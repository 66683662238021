
export default [
    { path: '/admin', name: 'admin', component: () => import('@/gestor/admin/Admin.vue'), meta: { noAuth: false } },
    { path: '/admin/paginas', name: 'Paginas', title: 'Paginas', component: () => import('./Paginas.vue'), meta: { noAuth: false } },
    { path: '/admin/pagina/:id', name: 'AdminPagina', title: 'Pagina', component: () => import('./Pagina.vue'), meta: { noAuth: false } },
    { path: '/admin/glosario/:id', name: 'GlosarioEditar', component: () => import('@/gestor/admin/GlosarioEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/glosario', name: 'AdminGlosarios', component: () => import('@/gestor/admin/Glosarios.vue'), meta: { noAuth: false } },
    { path: '/admin/indicador/:id', name: 'IndicadorEditar', component: () => import('@/gestor/admin/IndicadorEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/indicador/', name: 'Indicadores', component: () => import('@/gestor/admin/Indicadores.vue'), meta: { noAuth: false } },
    { path: '/admin/encuesta/:id', name: 'EncuestaEditar', component: () => import('@/gestor/admin/EncuestaEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/encuesta/', name: 'AdminEncuestas', component: () => import('@/gestor/admin/Encuestas.vue'), meta: { noAuth: false } },
    { path: '/admin/encuesta-menu/', name: 'EncuestasMenu', component: () => import('@/gestor/admin/EncuestasMenu.vue'), meta: { noAuth: false } },
    { path: '/admin/estudio/:id', name: 'EstudioEditar', component: () => import('@/gestor/admin/EstudioEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/estudio/', name: 'AdminEstudios', component: () => import('@/gestor/admin/Estudios.vue'), meta: { noAuth: false } },
    { path: '/admin/dato-abierto/:id', name: 'DatoAbiertoEditar', component: () => import('@/gestor/admin/DatoAbiertoEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/dato-abierto/', name: 'AdminDatosAbiertos', component: () => import('@/gestor/admin/DatosAbiertos.vue'), meta: { noAuth: false } },

    { path: '/admin/suscritos/', name: 'Suscritos', component: () => import('@/gestor/admin/Suscritos.vue'), meta: { noAuth: false } },
    { path: '/admin/busquedas/', name: 'Busquedas', component: () => import('@/gestor/admin/Busquedas.vue'), meta: { noAuth: false } },
    { path: '/admin/tracking/', name: 'Tracking', component: () => import('@/gestor/admin/Tracking.vue'), meta: { noAuth: false } },

    { path: '/admin/genero/', name: 'Genero', component: () => import('@/gestor/admin/Genero.vue'), meta: { noAuth: false } },
    { path: '/admin/genero-indicadores/', name: 'GeneroIndicadores', component: () => import('@/gestor/admin/GeneroIndicadores.vue'), meta: { noAuth: false } },
    { path: '/admin/genero-documentos/', name: 'GeneroDocumentos', component: () => import('@/gestor/admin/GeneroDocumentos.vue'), meta: { noAuth: false } },

    { path: '/admin/menu/:id', name: 'MenuEditar', component: () => import('@/gestor/admin/MenuEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/menu/', name: 'Menus', component: () => import('@/gestor/admin/Menus.vue'), meta: { noAuth: false } },
    { path: '/admin/bubble/', name: 'Bubble', component: () => import('@/gestor/admin/BubbleEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/home/', name: 'HomeEditar', component: () => import('@/gestor/admin/HomeEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/footer/', name: 'FooterEditar', component: () => import('@/gestor/admin/FooterEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/header/', name: 'HeaderEditar', component: () => import('@/gestor/admin/HeaderEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/suscripcion/', name: 'Suscripcionditar', component: () => import('@/gestor/admin/Suscripcionditar.vue'), meta: { noAuth: false } },
    { path: '/admin/conoce_observa/', name: 'ConoceObservarEditar', component: () => import('@/gestor/admin/ConoceObservarEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/galeria/', name: 'Galeria', component: () => import('@/gestor/admin/Galeria.vue'), meta: { noAuth: false } },
    { path: '/admin/programa-publico/', name: 'ProgramaPublicoEditar', component: () => import('@/gestor/admin/ProgramaPublicoEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/estudios-publicaciones/', name: 'ProgramaPublicoEditar', component: () => import('@/gestor/admin/EstudiosPublicacionesEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/programa-publico/instrumentos/', name: 'InstrumentosEditar', component: () => import('@/gestor/admin/InstrumentosEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/programa-publico/ciclo-aprendizaje', name: 'CicloAprendizaje', component: () => import('@/gestor/admin/CicloAprendizajeEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/privacidad/', name: 'privacidadEditar', component: () => import('@/gestor/admin/privacidadEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/auxiliares/', name: 'AuxiliaresEditar', component: () => import('@/gestor/admin/AuxiliaresEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/material-interes', name: 'MaterialInteres', component: () => import('@/gestor/admin/MaterialInteresEditar.vue'), meta: { noAuth: false } },
    { path: '/admin/mantenimiento/encuestas_sin_titulo_en_dspace', component: () => import('@/gestor/admin/mantenimiento/encuestas_sin_titulo_en_dspace.vue'), meta: { noAuth: false } }
]
/**
* Ruta a acciones de restablecimiento.
* { path: '/admin/mantenimiento/restablecer-dspace', name: 'RestablecerDspace', component: () => import('@/gestor/admin/RestablecerDspace.vue'), meta: { noAuth: false } }
 */
