<template>
    <div v-if="context">
        <section class="footer-top">
            <div class="container">
                <div class="row">
                    <div class="col-md-2">
                        <h2 class="section__title">{{suscripcion.footer_titulo}}</h2>
                    </div>
                    <div class="col-md-5">
                        <p>{{suscripcion.footer_texto}}</p>
                    </div>
                    <div class="col-md-5">
                        <form class="form-inline form--search">
                            <input class="form-control col-md-7 mb-2" type="email" placeholder="Email" aria-label="Search" v-model="form">
                            <modal-suscripcion :email="form" tipo='boton' @changeEmail="onEmailChanged"></modal-suscripcion>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <section class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <img class="logo-footer" src="/img/logo-footer.svg">
                        <p class="mb-5">{{context.texto}}</p>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-4">
                                <h3 class="footer-menu__title ml-0 ml-md-5">{{context.columna1.titulo}}</h3>
                                <ul class="footer-menu__list ml-0 ml-md-5 mb-5">
                                    <li class="footer-menu__item" v-for="link in context.columna1.links" :key="link.texto">
                                        <a :href="link.url" class="footer-menu__link">{{link.texto}}</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-4">
                                <h3 class="footer-menu__title">{{context.columna2.titulo}}</h3>
                                <ul class="footer-menu__list mb-5">
                                    <li class="footer-menu__item" v-for="link in context.columna2.links" :key="link.texto">
                                        <a :href="link.url" class="footer-menu__link">{{link.texto}}</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-4">
                                <h3 class="footer-menu__title">{{context.columna3.titulo}}</h3>
                                <ul class="footer-menu__list mb-5">
                                    <li class="footer-menu__item" v-for="link in context.columna3.links" :key="link.texto">
                                        <a :href="link.url" class="footer-menu__link">{{link.texto}}</a>
                                    </li>
                                    <!-- <li class="footer-menu__item">
                                        <modal-suscripcion tipo='link'></modal-suscripcion>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import ModalSuscripcion from '../components/ModalSuscripcion.vue'
import { utils } from '@/utils.js'
import axios from 'axios'

export default {
    name: 'footer',
    components: {
        ModalSuscripcion
    },
    mixins: [utils],
    data () {
        return {
            form: null,
            suscripcion: {}
        }
    },
    methods: {
        onEmailChanged (newValue) {
            this.form = newValue
        }
    },
    mounted () {
        this.load('Footer')
        axios.get('/api/paginas/?nombre=suscripcion').then(res => {
            this.suscripcion = res.data[0].datos
        })
    }
}
</script>
