import Vue from 'vue'
import App from './App'
import router from './router'
import 'bootstrap'
import { BootstrapVue } from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import VueSweetalert2 from 'vue-sweetalert2'

import 'sweetalert2/dist/sweetalert2.min.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './app.css'
import 'vuetify/dist/vuetify.min.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VueClipboard from 'vue-clipboard2'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faHome, faCaretUp, faCaretDown, faInfoCircle,
    faCircle, faSearch, faAdjust, faChevronRight,
    faChevronDown, faPlus, faBars, faEnvelope, faFilePdf,
    faFileWord, faFileExcel, faFileCsv, faFileImage, faCheck,
    faTimes, faMinus, faFile, faTrashAlt, faChevronLeft, faSave, faCalendar, faTrash, faDownload,
    faCopy
} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

// Hacer disponibles los íconos de fontawesome
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import Vuetify, {
    VApp, VMain, VDataTable, VChip, VAutocomplete
} from 'vuetify/lib'

window.$ = window.jQuery = require('jquery')

Vue.use(BootstrapVue)
Vue.use(VueGoodTablePlugin)
Vue.use(VueClipboard)
Vue.use(VueSweetalert2)
Vue.use(Vuetify, {
    components: {
        VApp, VMain, VDataTable, VChip, VAutocomplete
    }
})
Vue.component('multiselect', Multiselect)
Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(
    faFile, faHome, faLinkedin, faCaretUp,
    faCaretDown, faInfoCircle, faCircle,
    faSearch, faAdjust, faChevronRight,
    faChevronDown, faPlus, faBars, faEnvelope,
    faFilePdf, faFileWord, faFileExcel, faFileCsv,
    faFileImage, faCheck, faTimes, faMinus, faTrashAlt,
    faChevronLeft, faSave, faCalendar, faTrash, faDownload, faCopy
)
Vue.config.productionTip = false

Vue.filter('formatDate', function (value) {
    if (value) {
        var str = new Date(value)
        var locale = 'es-cl'
        return str.getDate() + ' de ' + str.toLocaleString(locale, { month: 'long' }) + ' ' + str.getFullYear()
    }
})

const opts = {
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    },
}
const DEFAULT_TITLE = 'Observa'
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE
    })
})

new Vue({
    vuetify: new Vuetify(opts),
    router,
    render: h => h(App)
}).$mount('#app')
