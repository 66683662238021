<template>
    <nav class="navbar navbar-expand-lg sticky-top">
        <div class="container">
            <router-link class="navbar-brand" to="/">
                <img class="navbar-brand__img" src="/img/logo2.svg" >
            </router-link>
            <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" id='burger'>
                <font-awesome-icon :icon="['fas', 'bars']" />
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ml-auto d-none d-md-flex justify-content-center">
                    <li class="nav-item" v-for="menu in menuHeader" :key="menu.titulo">
                        <router-link :class="[currentPage === menu.url ? activeClass: '', 'nav-link']" :to="menu.url">{{menu.titulo}}</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :class="[currentPage === '/busqueda-resultados' ? activeClass: '', 'nav-link']" to="/busqueda-resultados"> <font-awesome-icon :icon="['fas', 'search']" size="lg" /></router-link >
                    </li>
                    <li class="nav-item nav-item--accesibility nav-item--contrast">
                        <a @click="classBody('blackContrastDeactivate', null);" v-if="bodyClass==='blackContrast'">
                            <font-awesome-icon :icon="['fas', 'circle']" />
                        </a>
                        <a @click="classBody('blackContrast', null);" v-if="bodyClass==='blackContrastDeactivate'">
                            <font-awesome-icon :icon="['fas', 'adjust']" />
                        </a>
                    </li>
                    <li class="nav-item nav-item--accesibility">
                        <a @click="classBody(null, 'textBiggerDeactivate');" v-if="textBiggerClass==='textBigger'">
                            A <font-awesome-icon :icon="['fas', 'minus']" />
                        </a>
                        <a @click="classBody(null,'textBigger');" v-if="textBiggerClass==='textBiggerDeactivate'">
                            A <font-awesome-icon :icon="['fas', 'plus']" />
                        </a>
                    </li>
                </ul>
                <div class="accordion d-block d-md-none" id="accordionNavMenu" @click="collapseMenu()">
                    <ul class="navbar-nav">
                        <li class="nav-item" v-for="menu in menuHeader" :key="menu.titulo">
                            <router-link :class="[currentPage === menu.url ? activeClass: '', 'nav-link']" :to="menu.url">{{menu.titulo}}</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="d-flex gap-1 mr-5">
                    <div v-if="userAuthenticated" class="d-flex align-items-center gap-5">
                        <div id="user-info-btn">
                            <i class="fa-solid fa-user text-white"></i>
                            <span>{{ currentUserName }}</span>
                        </div>
                        <button class="btn btn-danger btn-sm ml-2" v-if="userAuthenticated" help="Cerrar sesión" @click="logout"><i
                                class="fa-solid fa-right-from-bracket"></i></button>
                    </div>

                </div>
    </nav>
</template>
<script>

import { utils } from '@/utils.js'
import axios from 'axios'
import _ from 'lodash'

export default {
    name: 'Header',
    mixins: [utils],
    data () {
        return {
            menusPrincipal: [],
            menusGenero: [],
            dataa: null,
            menuHeader: [],
            activeClass: 'nav-link--active',
            bodyClass: 'blackContrastDeactivate',
            textBiggerClass: 'textBiggerDeactivate',
            context: {},
            userAuthenticated: false,
            currentUserName: null
        }
    },
    updated () {
        this.userAuthenticated = (localStorage.getItem('jwt.access') && localStorage.getItem('jwt.access') !== '')
        var userInfo = (localStorage.getItem('current_user_info') && JSON.parse(localStorage.getItem('current_user_info'))) || null
        if (!userInfo || !this.userAuthenticated) {
            this.currentUserName = null
        } else {
            this.currentUserName = userInfo.nombres ? userInfo.nombres + ' ' + userInfo.apellidos : userInfo.username
        }
    },
    computed: {
        currentPage () {
            return this.$route.path
        }
    },
    methods: {
        collapseMenu () {
            document.getElementById('burger').click()
        },
        pushUrl (pagina, menu) {
            var params = { pagina: pagina.nombre, categoria: this.pasarSlug(menu.titulo) }
            this.$router.push({ name: 'EstadisticasPagina', params: params }).catch(() => {})
            this.collapseMenu()
        },
        classBody (clase, clase2) {
            if (clase) {
                document.body.classList.remove(this.bodyClass)
                this.bodyClass = clase
                if (clase === 'blackContrast') {
                    document.body.classList.add(clase)
                }
            }
            if (clase2) {
                document.body.classList.remove(this.textBiggerClass)
                this.textBiggerClass = clase2
                if (clase2 === 'textBigger') {
                    document.body.classList.add(clase2)
                }
            }
        },
        logout () {
            localStorage.removeItem('jwt.access')
            localStorage.removeItem('jwt.refresh')
            localStorage.removeItem('current_user_info')
            this.$router.push('/login')
        }
    },
    mounted () {
        axios.get('/api/menus?nombre=IndicadorPrincipal').then(res => {
            this.dataa = res.data
            this.menusPrincipal = _.sortBy(res.data[0].menu, 'order')
        })

        axios.get('/api/paginas/?nombre=header').then(res => {
            this.menuHeader = res.data[0].datos.menus
        })

        axios.get('/api/menus?nombre=IndicadorGenero').then(res => {
            this.menusGenero = _.sortBy(res.data[0].menu, 'order')
        })
        this.load('encuestas')
    }
}
</script>

<style scoped>
#user-info-btn {
    cursor: pointer;
    min-width: max-content !important;
}

#user-info-btn span {
    color: #fff;
    margin-left: 5px;
}

#btn-login{
    text-decoration: none;
}
</style>
