<template>
    <div v-if="context">
        <section class="home__first-section">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-md-10">
                        <h1 class="page__title page__title--home">{{context.section.buscador.titulo}}</h1>
                        <div class="row justify-content-center">
                            <div class="col-md-6">
                                <form class="form-inline form-search form-search--home" @submit.prevent="1">
                                    <font-awesome-icon :icon="['fas', 'search']" />
                                    <!-- <input class="form-control" type="search" placeholder="Busca datos e indicadores aquí" aria-label="Search"> -->
                                    <vue-typed-js :attr="'placeholder'" :strings="[''.concat(context.section.buscador.placeholder)]" :typeSpeed="100" :backSpeed="0" :loop="true" :cursorChar="'_'" :bindInputFocusEvents= "true">
                                      <input v-on:keydown.enter='dspace' class="form-control typing" type="search" placeholder="" aria-label="Search" v-model='searchable'>
                                    </vue-typed-js>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="resultados__second-section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-11">
                        <div class="home-deck">
                            <div v-for="tarjeta in context.section.tarjetas" :key="tarjeta.id" class="card card--datos home-card">
                                <div class="card-body">
                                    <small class="datos__year">{{tarjeta.año}}</small>
                                    <a :href="tarjeta.titulo_url"><h3 class="datos__title datos__title--home">{{tarjeta.titulo}}</h3></a>
                                    <h4 class="datos__number" :style="[tarjeta.arrow ? {'font-size': '1.5rem'}: {'font-size': '2.5rem'}]">
                                        <animated-number v-if="tarjeta.percent" :value="tarjeta.valor" :duration="duration" :formatValue="formatToPercent" />
                                        <animated-number v-else :value="tarjeta.valor" :duration="duration" :formatValue="formatToNumber"/>
                                    </h4>

                                    <small v-if="tarjeta.arrow" :class="'datos__percent datos__percent--'.concat(tarjeta.color)">
                                        <font-awesome-icon :icon="get_icon(tarjeta)" />
                                        {{ tarjeta.variacion }}
                                        <font-awesome-icon v-if="tarjeta.tooltip && tarjeta.tooltip.length>0" :id="'tooltip-target-'+index" :icon="['fas', 'info-circle'] " />
                                        <b-tooltip v-if="tarjeta.tooltip && tarjeta.tooltip.length>0" :target="'tooltip-target-'+index" triggers="hover">{{tarjeta.tooltip}}</b-tooltip>
                                    </small>
                                </div>
                                <div class="card-footer">
                                   <small class="datos__source">Fuente: <a :href="tarjeta.fuente_url"><strong>{{tarjeta.fuente}}</strong></a></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="categorias-indicadores">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="section__title section__title--md">Indicadores</h2>
                        <ul class="categorias-indicadores__list" v-if="context">
                            <li class="categorias-indicadores__item" v-for="indicador in context.section.indicadores" v-bind:key="indicador.id">

                                <a v-if="indicador.url && indicador.url.length>1" :href="indicador.url"><span class="categorias-indicadores__text">{{indicador.titulo}}</span> <span class="categorias-indicadores__number">{{indicador.cantidad}}</span></a>
                                <router-link v-if="!(indicador.url && indicador.url.length>1)" :to="'indicadoresinternal?categoria=' + indicador.id"><span class="categorias-indicadores__text">{{indicador.titulo}}</span> <span class="categorias-indicadores__number">{{indicador.cantidad}}</span></router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <material-interes
        v-if="pagina"
        title="Actualizaciones recientes"
        :indicador_destacado="context.section.indicador_destacado"
        :anexos_actualizados="pagina.anexos_actualizados" />

        <section class="sobre-el-sistema">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <h2 class="section__title">{{context.section.sobre_el_sistema.titulo}}</h2>
                        <p class="section__description">{{context.section.sobre_el_sistema.descripcion}}</p>
                        <a href="/sistema" class="btn btn-primary">{{context.section.sobre_el_sistema.boton}}</a>
                    </div>
                    <div class="col-md-7 offset-1">
                        <router-link to="/sistema"><img :src="this.getUrlGaleria(context.section.sobre_el_sistema.imagen)"></router-link>
                    </div>
                </div>
            </div>

        </section>
    </div>
</template>

<script>
import { utils } from '@/utils.js'
import Vue from 'vue'
import AnimatedNumber from 'animated-number-vue'
import VueTypedJs from 'vue-typed-js'
import axios from 'axios'
import MaterialInteres from '@/components/MaterialInteres.vue'

Vue.use(VueTypedJs)
export default {
    name: 'Home',
    components: {
        AnimatedNumber,
        MaterialInteres
    },
    mixins: [utils],
    data () {
        return {
            duration: 800,
            pagina: null,
            searchable: '',
            formatToPercent (value) {
                return ` ${value.toFixed(2).toString().replace('.', ',')} %`
            },
            formatToNumber (value) {
                return ` ${value.toFixed(1).toString().replace('.', ',')}`
            },
            // componente: { orden: 1,
            // url: 'https://public.tableau.com/views/Indicadores_OCDE_BC/Dashboard020202-Investigadoresc1000habitantes?:language=es&:display_count=y&:origin=viz_share_link:showVizHome=no&amp;:embed=true&:embed=y&:showVizHome=n&:tabs=n&:apiID=host3#navType=1&navSrc=Parse:showVizHome=no&amp;:embed=true',
            // type: 'chart' }
        }
    },
    methods: {
        get_icon (tarjeta) {
            if (tarjeta.arrow === 'up') {
                return ['fas', 'caret-up']
            }
            if (tarjeta.arrow === 'neutral') {
                return ['fas', 'circle']
            }
            if (tarjeta.arrow === 'down') {
                return ['fas', 'caret-down']
            }
        },
        dspace () {
            var self = this
            self.$router.push({ name: 'BusquedaResultados', params: { searchable: self.searchable } })
        }
    },
    mounted () {
        var tableau = document.createElement('script')
        tableau.setAttribute('src', 'https://public.tableau.com/javascripts/api/tableau-2.7.0.min.js')
        document.head.appendChild(tableau)
        this.load('home')
        axios.get('/api/paginas/?nombre=home').then(res => {
            this.pagina = res.data[0]
        })
    }
}
</script>
<style>
    .home-deck {
        display: grid;
        gap: 2rem;
        grid-auto-rows: 13rem;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    }
    .home-card {
        padding: 12px;
    }
</style>
