<template>
    <div v-if="suscripcion">
        <b-modal :id="'modal-suscripcion'+tipo" size="md">
            <div v-if="create && !success">
                <div class="row text-center">
                    <div class="form-group col-md-8 offset-md-2">
                        <h2 class="section__title mb-0">{{suscripcion.modal_titulo}}</h2>
                    </div>
                    <div class="form-group col-md-8 offset-md-2">
                        <p class="">{{suscripcion.modal_texto}}</p>
                        <p v-show="error" style="color: red">{{error}}</p>
                    </div>
                    <div class="form-group col-md-8 offset-md-2">
                        <!-- <span v-if="email">{{email}}</span> -->
                        <input type="text" class="form-control" v-model="formSuscripcion.email" placeholder="Correo Electrónico" />
                    </div>
                    <div class="form-group col-md-8 offset-md-2">
                        <input type="text" class="form-control" v-model="formSuscripcion.nombre" placeholder="Nombre" />
                    </div>
                    <div class="form-group col-md-8 offset-md-2">
                        <input type="text" class="form-control" v-model="formSuscripcion.apellido" placeholder="Apellido" />
                    </div>
                    <div class="form-group col-md-8 offset-md-2">
                        <b-form-select v-model="formSuscripcion.sector" :options="sector"></b-form-select>
                    </div>
                    <div class="form-group col-md-8 offset-md-2">
                        <input type="text" class="form-control" v-model="formSuscripcion.lugar" placeholder="Empresa, Institución o Lugar de Afiliación" />
                    </div>
                    <div class="form-group col-md-8 offset-md-2">
                        <input type="text" class="form-control" v-model="formSuscripcion.cargo" placeholder="Campo, Cargo u Ocupación" />
                    </div>
                    <div class="form-group col-md-8 offset-md-2">
                        <b-form-select v-model="formSuscripcion.region" :options="region" ></b-form-select>
                    </div>
                    <!--
                    <div class="form-group col-md-8 offset-md-2" v-for="campo in campos_adicionales" :key="campo">
                        <input type="text" class="form-control" v-model="formSuscripcion[campo]" :placeholder="campo" />
                    </div>
                    -->
                </div>
            </div>
            <div v-if="!create && success">
                <div class="row">
                    <div class="form-group col-md-8 offset-md-2">
                        {{suscripcion.enviado_titulo}}
                    </div>
                    <div class="form-group col-md-8 offset-md-2">
                        {{custom_success_message || suscripcion.enviado_texto}}
                    </div>
                    <div class="form-group col-md-8 offset-md-2" v-if="email">
                        {{email}}
                    </div>
                </div>
            </div>
            <div v-if="!create && !success">
                <div class="row">
                    <div class="form-group col-md-8 offset-md-2">
                        {{suscripcion.error_titulo}}
                    </div>
                    <div class="form-group col-md-8 offset-md-2">
                        {{custom_error_message ||suscripcion.error_texto}}
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <a @click="checkDatos()"  v-if="create && !success && !enviando" class="btn btn-primary">{{suscripcion.modal_boton}}</a>
                <b-button v-if="!create && success" size="md" variant="outline-secondary" @click="closeModal()">
                    Cerrar
                </b-button>
                <a @click="goBack()" v-if="!create && !success" class="btn btn-primary">Regresar</a>
            </template>
        </b-modal>
        <div class="" v-if="tipo==='boton'">
            <b-button @click="openModal(); formSuscripcion.email=email" class="btn btn-primary mb-2">
                {{suscripcion.footer_boton}}
            </b-button>
        </div>

        <a v-if="tipo==='link'" href="#" @click="openModal()" class="footer-menu__link">
            Suscríbete
        </a>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    name: 'ModalSuscripcion',
    props: ['email', 'tipo'],
    data () {
        return {
            // formSuscripcion: {},
            formSuscripcion: { email: '', nombre: '', apellido: '', sector: '', lugar: '', cargo: '', region: '', activo: true },
            suscripcion: null,
            sector: [
                { value: '', text: 'Sector' },
                { value: 'Academia', text: 'Academia' },
                { value: 'Divulgadores', text: 'Divulgadores' },
                { value: 'Organismo Multilateral', text: 'Organismo Multilateral' },
                { value: 'Sector Privado', text: 'Sector Privado' },
                { value: 'Sector Público', text: 'Sector Público' },
                { value: 'Sociedad Civil', text: 'Sociedad Civil' },
                { value: 'Otro', text: 'Otro' },
            ],
            region: [
                { value: '', text: 'Region' },
                { value: 'Arica y Parinacota', text: 'Arica y Parinacota' },
                { value: 'Tarapacá', text: 'Tarapacá' },
                { value: 'Antofagasta', text: 'Antofagasta' },
                { value: 'Atacama', text: 'Atacama' },
                { value: 'Coquimbo', text: 'Coquimbo' },
                { value: 'Valparaíso', text: 'Valparaíso' },
                { value: 'Metropolitana', text: 'Metropolitana' },
                { value: 'O\'Higgins', text: 'O\'Higgins' },
                { value: 'Maule', text: 'Maule' },
                { value: 'Ñuble', text: 'Ñuble' },
                { value: 'Biobío', text: 'Biobío' },
                { value: 'La Araucanía', text: 'La Araucanía' },
                { value: 'Los Ríos', text: 'Los Ríos' },
                { value: 'Los Lagos', text: 'Los Lagos' },
                { value: 'Aysén', text: 'Aysén' },
                { value: 'Magallanes', text: 'Magallanes' },
                { value: 'Otro / Extranjero', text: 'Otro / Extranjero' }
            ],
            // campos_adicionales: [],
            create: true,
            enviando: false,
            success: false,
            error: null,
            custom_success_message: null,
            custom_error_message: null
        }
    },
    methods: {
        openModal () {
            // this.formSuscripcion = {}
            this.formSuscripcion = { email: '', nombre: '', apellido: '', sector: '', lugar: '', cargo: '', region: '', activo: true }
            this.$root.$emit('bv::show::modal', 'modal-suscripcion' + this.tipo)
        },
        closeModal () {
            this.formSuscripcion = {}
            this.create = true
            this.success = false
            this.$root.$emit('bv::hide::modal', 'modal-suscripcion' + this.tipo)
        },
        subscribeUser () {
            this.enviando = true
            this.custom_error_message = null
            // this.formSuscripcion.email = this.email
            axios({
                method: 'post',
                url: process.env.VUE_APP_BASEURL + '/api/api/send_subscription_mail/',
                data: this.formSuscripcion
            })
                .then((response) => {
                    console.log('mensaje exito: ', response)
                    this.create = false
                    this.success = true
                    this.enviando = false

                    if (response.status === 200) {
                        this.custom_success_message = response?.data?.message || 'Hemos enviado un correo electrónico a la dirección de correo registrada. Por favor, revise su bandeja de entrada y siga las instrucciones para completar su suscripción.'
                    } else {
                        this.custom_success_message = null
                    }
                })
                .catch((error) => {
                    console.log('mensaje fallo: ', error.response)
                    if (error?.response?.data?.message) {
                        this.custom_error_message = error.response.data.message
                    }
                    this.create = false
                    this.success = false
                    this.enviando = false
                })
        },
        goBack () {
            this.create = true
            this.success = false
        },
        isNotEmpty (str) {
            return (str && str.length > 0)
        },
        checkDatos () {
            this.error = ''
            if (this.isNotEmpty(this.formSuscripcion.nombre) &&
                this.isNotEmpty(this.formSuscripcion.apellido) &&
                this.isNotEmpty(this.formSuscripcion.lugar) &&
                this.isNotEmpty(this.formSuscripcion.cargo) &&
                (this.formSuscripcion.sector !== '') &&
                (this.formSuscripcion.region !== '')) {
                if (this.checkEmail(this.formSuscripcion.email)) {
                    this.formSuscripcion.email = this.formSuscripcion.email.toLowerCase().trim()
                    axios({
                        method: 'get',
                        url: process.env.VUE_APP_BASEURL + '/api/api/check_subscription_mail/' + this.formSuscripcion.email,
                    })
                        .then((response) => {
                            console.log(response.data.result)
                            if (response.data.result === 'existe') {
                                // this.error = 'Email ya ingresado'
                                // this.TogglePopup()
                                this.error = 'Ya contamos con tu información, ya sea porque te registraste anteriormente o porque participaste en alguna actividad de la Oficina de Estudios y Estadísticas y señalaste que querías recibir alertas de nuevos contenidos y eventos. Si crees que hay algún error, puedes escribirnos a ' + this.MC_CONTACT_EMAIL
                            } else if (response.data.result === 'no_existe') {
                                this.subscribeUser()
                            }
                        })
                        .catch((response) => {
                            console.log(response)
                            // this.subscribeUser() // aqui
                        })
                } else {
                    this.error = 'Ingresa un email válido'
                }
            } else {
                this.error = 'Completa todos los datos'
            }
        },
        checkEmail (email) {
            return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
        }
    },
    mounted () {
        this.formSuscripcion.email = this.email
        this.MC_CONTACT_EMAIL = process.env.MC_CONTACT_EMAIL || 'atencionciudadana@minciencia.gob.cl'

        axios.get('/api/paginas/?nombre=suscripcion').then(res => {
            this.suscripcion = res.data[0].datos
            /* if (res.data[0].datos.modal_campos_adicionales) {
                this.campos_adicionales = res.data[0].datos.modal_campos_adicionales.split(';')
            } */
        })

        this.$watch('formSuscripcion.email', (newValue) => {
            if (newValue && newValue !== this.email) {
                this.email = newValue
                this.$emit('changeEmail', newValue)
            }
        })
    }
}
</script>
