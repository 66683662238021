<template>
    <div id="app">
        <sitio-header></sitio-header>
        <router-view :class="'h-100'"/>
        <sitio-footer></sitio-footer>
    </div>
</template>

<script>
import SitioHeader from '@/components/Header.vue'
import SitioFooter from '@/components/Footer.vue'
export default {
    name: 'App',
    components: {
        SitioHeader,
        SitioFooter
    }
}
</script>
