<template>
    <div >

        <div class="card card--news" :class="clase">
            <div class="row no-gutters">
                <div v-bind:class="{ 'col-md-5': tipo==='horizontal','col-md-12': tipo==='vertical' }">
                    <div class="card-container-top">
                        <img v-if="tiene_img_fondo()" :src="'https://observa.minciencia.gob.cl:447/api/galeria/${estudio.datos.tarjeta.fondo_galeria.id}/thumb/'" style="width:100%;height:100%">
                        <div v-if="!tiene_img_fondo()" class="card-text">
                            <a :href="url">
                            <h3>{{estudio.datos.metadata["dc.title"]}}</h3>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-bind:class="{ 'col-md-7': tipo==='horizontal','col-md-12': tipo==='vertical' }">
                    <div class="card-body">
                        <span class="card__tag" v-if="estudio.datos.tipo_documento">{{estudio.datos.tipo_documento[0]}}</span>
                        <small class="card__date">{{estudio.datos.metadata["dc.date.available"].split('-')[0]}}</small>
                        <a :href="url">
                        <h5 class="card-title">{{estudio.datos.metadata["dc.title"]}}</h5>
                        </a>
                        <p class="card-text-body" v-html='estudio.datos.metadata["dc.description.abstract"]'></p>
                        <a :href="url" class="btn btn-primary">Ver estudio</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { utils } from '@/utils.js'
export default {
    name: 'ActualizacionReciente',
    components: {},
    mixins: [utils],
    props: ['estudio', 'clase', 'tipo'],

    data () {
        return {
            context: null,
            url: '/estudios/' + this.pasarSlug(this.estudio.datos.metadata['dc.title']),
        }
    },
    methods: {
        tiene_img_fondo () {
            if (this.estudio.datos.tarjeta && this.estudio.datos.tarjeta.fondo_galeria) { return true }
            return false
        }
    },
    mounted () {
    }
}
</script>
