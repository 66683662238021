<template>
    <section class="actualizaciones-recientes" v-if="anexos_actualizados.estudios">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="section__title section__title--md">{{title}}</h2>
                </div>
            </div>

            <div class="row justify-content-center" v-if="unaColumna" >
                <div class="col-12 col-md-8">
                    <actualizacion-reciente :estudio="anexos_actualizados.estudios[0]" tipo='horizontal' clase="card--news-1" v-if="anexos_actualizados.estudios.length>0"></actualizacion-reciente>
                    <actualizacion-reciente :estudio="anexos_actualizados.estudios[1]" tipo='horizontal' clase="card--news-2" v-if="anexos_actualizados.estudios.length>1"></actualizacion-reciente>
                </div>
            </div>

            <div class="row" v-if="!unaColumna" >
                <div class="col-12 col-md-4">
                    <actualizacion-reciente :estudio="anexos_actualizados.estudios[0]" tipo='vertical' clase="card--news-1" v-if="anexos_actualizados.estudios.length>0"></actualizacion-reciente>
                    <actualizacion-reciente :estudio="anexos_actualizados.estudios[1]" tipo='vertical' clase="card--news-2" v-if="anexos_actualizados.estudios.length>1 && has_indicador()"></actualizacion-reciente>
                </div>
                <div class="col-12 col-md-8">
                    <actualizacion-reciente  :estudio="anexos_actualizados.estudios[1]" tipo='horizontal' clase="card--news-2" v-if="anexos_actualizados.estudios.length>1 && !has_indicador()"></actualizacion-reciente>
                    <actualizacion-reciente :estudio="anexos_actualizados.estudios[2]"  tipo='horizontal' clase="card--news-3" v-if="anexos_actualizados.estudios.length>2"></actualizacion-reciente>
                    <div v-if="has_indicador()" class="card card--news-chart" style="overflow:hidden; max-height: 940px">
                        <div class="card-body" >
                            <a :href="indicador_destacado.link"><h5 class="card-title">{{indicador_destacado.titulo}}</h5></a>
                                <chart :componente=" {url:indicador_destacado.tableau , orden: 1, type: 'chart' }" :home="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import ActualizacionReciente from '../components/ActualizacionReciente.vue'
import Chart from '@/gestor/Chart.vue'

export default {
    name: 'MaterialInteres',
    props: ['anexos_actualizados', 'indicador_destacado', 'title', 'unaColumna'],
    components: { ActualizacionReciente, Chart },
    data () {
        return {}
    },
    methods: {
        has_indicador () {
            if (this.indicador_destacado && this.indicador_destacado.link) {
                return true
            }
            return false
        }

    },
    mounted () {
    }
}
</script>
<style scoped>
/*
.card--news, .card--news-chart { margin-bottom: 2rem; }
.card__tag {
font-family: 'Source Sans Pro', sans-serif;
font-size: .7rem;
font-weight: 700;
display: inline-block;
background: #F0F0F0;
color: #000;
text-transform: uppercase;
letter-spacing: 1px;
padding: 0 .8rem;
  margin-right: .5rem;
border-radius: 20px;
margin-bottom: 1rem;
}
.card--news .card__date { float: right; color: #898989; font-size: .8rem; font-family: 'Source Sans Pro', sans-serif }
.card--news .card-title { color: #000099; font-weight: 700; font-size: 1rem;  line-height: 1.6rem;}
.card--news .card-text { font-size: .8rem; }
.card--news .card-img__container {
  overflow: hidden;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  background-image: url('/img/texture_3.svg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-width: 300px;
  min-height: 275px;
  height: 100%;
}
.card--news .card-img__container-2 {
  background-image: url('/img/texture_4.svg');
  background-repeat: no-repeat;
  background-size: cover;
}
.card-text--left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    background: #000099;
    border-radius: 50%;
    height: 250px;
    width: 250px;
    color: #fff;
    text-align: center;
}
.card--news .card-img__container-2 .card-text--left {
  background: #fff;
  color: #000099;
}
.card--news .card-img__container--top {
  background-image: url('/img/texture_2.svg');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  position: relative;
  min-height: 300px;
}
.card--news .card-img__container--top-2 {
  background-image: url('/img/texture_1.svg');
  background-repeat: no-repeat;
  background-size: cover;
}
.card-text-top {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    border-radius: 50%;
    height: 250px;
    width: 250px;
    text-align: center;
    background: #fff;
    color: #000099;
}
.card--news .card-img__container--top-2 .card-text-top {
  background: #000099;
  color: #fff;
}
.card-text--left h3,
.card-text-top h3 {
  font-size: 1.2rem;
  line-height: 1.8rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    padding: 1.5rem;
    width: 100%;
}
.card--news .card-img { height: auto; max-height: 100%;}

.card--news-chart { padding: 1rem; }
.card--news-chart .card-title { color: #000; font-family: 'Source Sans Pro', sans-serif; font-weight: 700; font-size: 1rem; }
.card--news-chart .card__date { display: block; font-family: 'Source Sans Pro', sans-serif; font-size: .8rem; margin-bottom: 1.8rem; }
.card--news-chart .card__img { margin-bottom: 1.2rem; }
.card--news-chart .card__source { color: #898989; font-family: 'Source Sans Pro', sans-serif; font-weight: 300; line-height: 15px; display: block; font-size: .8rem; }
.card--news-chart .card__source strong { color: #000099; }

.card--datos-abiertos .card-title { color: #000; font-family: 'Source Sans Pro', sans-serif; font-weight: 700; font-size: 1rem; }
.card--datos-abiertos th { background: #f7f7f7; }

@media (max-width: 1198px){
  .actualizaciones-recientes .col-md-4,
  .actualizaciones-recientes .col-md-8 {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%; }
}
@media (max-width: 768px) {
  .card--news .card-img__container {
    border-bottom-left-radius: 0;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
} */

</style>
